body {
  background-color: #cebf9e;
}

a {
  text-decoration: none;
  font-size: 28px;
  font-weight: bold;
}

iframe {
  border: none;
  width: 100%;
  height:100%;
}